import React, { useEffect, useState } from 'react'
import UserLists from '../UserLists/UserLists'

import './Container.css'

const Container = () => {

  return (
    <div className='Container'>
     
        <UserLists />
        
     
    </div>
  )
}

export default Container
